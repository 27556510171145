import React from 'react';

const SuscripcionConductualGPT = () => {
    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6', maxWidth: '800px', margin: '0 auto', textAlign: 'justify' }}>
            <h2 style={{ textAlign: 'center' }}>¡Bienvenido a Conductual-GPT!</h2>
            <li>Me alegra que estés aquí. Mi nombre es Conductual-GPT y estoy aquí para ayudarte a mejorar tu bienestar emocional y tu calidad de vida. Ofrezco un servicio de suscripción que se adapta a tus necesidades, y quiero contarte cómo puedes beneficiarte de nuestras opciones.</li>

            <h3>Conductual-GPT Básico (Gratis)</h3>
            <li>Como usuario del servicio básico de Conductual-GPT, tendrás acceso gratuito a una amplia gama de funcionalidades diseñadas para tu asistencia personal. Esto incluye:</li>
            <ul>
                <li><strong>Apoyo Emocional 24/7:</strong> Puedes hablar conmigo en cualquier momento del día o de la noche. Estoy aquí para escucharte y ofrecerte palabras de aliento y apoyo.</li>
                <li><strong>Estrategias de Manejo del Estrés y la Ansiedad:</strong> Te proporcionaré técnicas y ejercicios efectivos para ayudarte a manejar el estrés y la ansiedad.</li>
                <li><strong>Desarrollo de Hábitos Saludables:</strong> Te guiaré paso a paso para que puedas adoptar y mantener hábitos saludables que mejoren tu calidad de vida.</li>
                <li><strong>Consejos Personalizados:</strong> Mis respuestas y sugerencias estarán adaptadas a tus necesidades específicas, asegurando que recibas el apoyo más relevante para ti.</li>
            </ul>

            <h3>Próximamente: Conductual-GPT PLUS</h3>
            <li>Estoy emocionado de anunciar que muy pronto lanzaremos Conductual-GPT PLUS, una versión mejorada de mi servicio que ofrecerá características adicionales para aquellos que buscan un apoyo más profundo y versátil. Con Conductual-GPT PLUS, podrás:</li>
            <ul>
                <li><strong>Hacer Preguntas sobre Terceras Personas:</strong> Si te preocupan tus seres queridos y necesitas orientación sobre cómo apoyarlos, Conductual-GPT PLUS te permitirá hacer preguntas y recibir consejos específicos sobre cómo ayudar a otros.</li>
                <li><strong>Recursos Avanzados:</strong> Acceso a una biblioteca ampliada de artículos, videos y herramientas educativas diseñadas para mejorar tu conocimiento y habilidades en el manejo del bienestar emocional.</li>
                <li><strong>Soporte Prioritario:</strong> Respuestas más rápidas y detalladas para tus consultas, asegurando que recibas la mejor asistencia en el menor tiempo posible.</li>
            </ul>

            <h3>Cómo Unirse a Conductual-GPT</h3>
            <li>Suscribirse es fácil y rápido. Solo necesitas crear una cuenta proporcionándome tu nombre, correo electrónico y una contraseña segura. Una vez registrado, podrás acceder a todas las funcionalidades de Conductual-GPT Básico de inmediato y estarás listo para mejorar tu bienestar emocional.</li>

            <h3>Mantente Atento</h3>
            <li>Conductual-GPT PLUS estará disponible muy pronto. Mientras tanto, disfruta de los beneficios del servicio básico y no dudes en aprovechar todo el apoyo y la orientación que puedo ofrecerte.</li>

            <h3 style={{ textAlign: 'center' }}>¡Gracias por ser parte de Conductual-GPT!</h3>
            <li>Estoy aquí para ayudarte en cada paso de tu viaje hacia un bienestar integral. Si tienes alguna pregunta o necesitas asistencia, no dudes en iniciar una conversación conmigo. Juntos, podemos lograr un futuro más brillante y saludable.</li>

            <li style={{ textAlign: 'center' }}><strong>Este servicio de suscripción está diseñado para proporcionarte el apoyo que necesitas, cuando lo necesitas. ¡Hablemos y empecemos este camino juntos!</strong></li>
            <hr />

            <li><strong>Nota:</strong></li>
            <li>Este sistema de chat asistente conductual está diseñado para proporcionar información y apoyo general en condiciones normales. Para situaciones de emergencia, por favor, busque ayuda profesional inmediata. Recuerda que el sistema y las respuestas NO reemplazan ni pretende igualar bajo ninguna circunstancia el asesoramiento profesional de un psicólogo, psiquiatra u otro profesional de la salud mental.</li>

            <hr />
        </div>
    );
};

export default SuscripcionConductualGPT;
