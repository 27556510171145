import React, { useEffect, useRef } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import TerminosDeUso from './disclaimer/TerminosDeUso';
import './Modal.css';


const Modal = ({ isOpen, onClose }) => {
    const handleClose = () => {
      onClose();
    };
  
    if (!isOpen) {
      return null;
    }

  return (
    <>
      
        <div className="modal-wrapper-terminos">
          <div className="modal-content-terminos"><button className="close-button-terminos" onClick={handleClose}>
                <AiOutlineClose />
              </button>
            <div className="modal-header-terminos">
            Terminos y condiciones de uso
            </div>
            <div className="modal-body-terminos">
            <TerminosDeUso/>
            </div>
          </div>
        </div>
      
    </>
  );
};

export default Modal;