import { useEffect } from 'react';

const useAutoScroll = (messagesEndRef, currentChat, enabled) => {
  const handleScrolling = () => {
    const scrollToBottom = (ref, delay) => {
      if (ref.current && enabled) {
        setTimeout(() => {
          ref.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "end" });
        }, delay);
      }
    };

    const delays = [0, 200, 900, 1800, 1900];

    delays.forEach((delay) => scrollToBottom(messagesEndRef, delay));
  };

  useEffect(() => {
    if (enabled) {
      handleScrolling();
    }
  }, [currentChat, enabled]);
};

export default useAutoScroll;
