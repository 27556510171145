import React, { useState, useRef, useEffect } from 'react';
import '../App.css'; // Estilos CSS para el chat
import { 
    FaArrowDown, 
    FaCaretDown, 
    FaArrowRight, 
    FaSignInAlt, 
    FaSignOutAlt} from 'react-icons/fa'; // Importa los iconos necesarios
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Terminos from './terminosDeUso';
import Suscripciones from './suscripciones';
import './Modal.css';
import FilteredPosts from './historialTrained'; //// historial
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import client from '../apolloClient';
import { StreamingText } from './modulos-propios/streamingText';
import useAutoScroll from './modulos-propios/useAutoScrollSigleTrained'; // Importa el módulo useAutoScroll
import useScrollToBottom from './modulos-propios/useSingleScrolledToBottom'; // Importa el módulo useScrollToBottom
import useClickOutside from './modulos-propios/useClickOutside'; // Importa el módulo useClickOutside
import Nuevo from './icons/nuevo';
import AbrirSide from './icons/arrowLeft';
import CerrarSide from './icons/arrowRigth';
import DeleteButton from './deleteAndLikes/deleteButomTrained';
import Puntitos from './deleteAndLikes/Spinner';

const useradmin = process.env.REACT_APP_ADMIN || process.env.REACT_APP_ADMIN2 || process.env.REACT_APP_ADMIN3

const FETCH_POSTS_QUERY_USER_TAINED = gql`
  query getPostsTrainByUsername($username: String!) {
    getPostsTrainByUsername(username: $username) {
      id
      titulo
      createdAt
      username
    }
  }
`;

const FETCH_POST_QUERY_TRAINED = gql`
  query($postId: ID!) {
    getPostTrain(postId: $postId) {
        id
        titulo
        cuerpo
        resumen
        tacs
        materia
        username
        contexto{
          id
          user
          assistant
          createdAt
        }
    }
  }
`;

const SUBMIT_COMMENT_MUTATION_TEST = gql`
  mutation($postId: String!, $body: String!,$username: String!) {
    createCommentTest(postId: $postId, body: $body, username: $username) {
      id
      contexto {
        id
        user
        assistant
        createdAt
      }
    }
  } 
`;

const SUBMIT_CONTEXTOMASIVO_MUTATION = gql`
  mutation($postId: String!) {
    createContextoMasivo(postId: $postId) {
        id
        titulo
        cuerpo
        tacs
        resumen
        contexto{
          user
          assistant
        }
    }
  } 
`;

const Chat = () => {
  const { postId } = useParams();
  const [showSidebar, setShowSidebar] = useState(true); // Estado para controlar la visibilidad del sidebar
  const [showDropdown, setShowDropdown] = useState(false);
  useClickOutside(showDropdown, setShowDropdown); // Utiliza el módulo useClickOutside
  const [currentChat, setCurrentChat] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuscripcionesOpen, setSuscripcionesOpen] = useState(false);
  const [messageInput, setMessageInput] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);
  const messagesEndRef = useRef(null);
  const [isRefActive, setIsRefActive] = useState(true);
  const bodymessagesEndRef = useRef(null);
  const isScrolledToBottom = useScrollToBottom(bodymessagesEndRef); // Utiliza el módulo useScrollToBottom
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { loginWithRedirect, logout } = useAuth0();
  const navigate = useNavigate();
  const username = user.sub.replace(/[^a-zA-Z0-9]/g, '');
  const [post, setPost] = useState(null);
  const [titulo, setTitulo] = useState('');
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);


  

  const manualScrollToBottom = () => {
    if (messagesEndRef.current && typeof messagesEndRef.current.scrollIntoView === 'function') {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "end" });
    }
  };

  useAutoScroll(messagesEndRef, currentChat, autoScrollEnabled);

  useEffect(() => {
    if (messagesEndRef.current) {
      manualScrollToBottom();
    }
  }, [currentChat, messagesEndRef]);
  


  useEffect(() => {
    const handleResize = () => {
      manualScrollToBottom();
    };
  
    const resizeObserver = new ResizeObserver(() => {
      handleResize();
    });
  
    const observer = new MutationObserver(() => {
      manualScrollToBottom();
    });
    const config = { childList: true, subtree: true };
  
    if (messagesEndRef.current && messagesEndRef.current.parentNode) {
      resizeObserver.observe(messagesEndRef.current.parentNode);
      observer.observe(messagesEndRef.current.parentNode, config);
    }
  
    return () => {
      if (messagesEndRef.current && messagesEndRef.current.parentNode) {
        resizeObserver.disconnect();
        observer.disconnect();
      }
    };
  }, [currentChat, messagesEndRef]); // Agrega messagesEndRef aquí

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const { loading2, error2, data2, refetch } = useQuery(FETCH_POSTS_QUERY_USER_TAINED, {
    variables: { username }
  });

  const handleNewChat = () => {
    navigate(`/trained`);
    refetch();
  };

  const { loading, error, data } = useQuery(FETCH_POST_QUERY_TRAINED, {
    variables: { postId },
    skip: postId ? false : true, // Skip si postId es undefined al montar el componente
  });

  useEffect(() => {
    if (data && data.getPostTrain) {
      const post = data.getPostTrain;
      setPost(post);
      setTitulo(post.titulo);
      if (post && Array.isArray(post.contexto)) {
        const messages = post.contexto.flatMap(comment => [
          { text: comment.user, sender: 'user', createdAt: comment.createdAt, commentId: comment.id},
          comment.assistant && { text: comment.assistant, sender: 'bot', createdAt: comment.createdAt, commentId: comment.id}
        ]).filter(Boolean);
        const messagesToShow = messages.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        setCurrentChat(messagesToShow);
      }
    }
  }, [data]);

  const sendMessage = () => {
    if (messageInput.trim() !== '') {
      setIsStreaming(true);
      const newMessage = {
        text: <>{messageInput}<Puntitos /></> ,
        sender: 'user'
      };
      setCurrentChat([...currentChat, newMessage]);

      client.mutate({
        mutation: SUBMIT_COMMENT_MUTATION_TEST,
        variables: { postId: postId, body: messageInput, username: username },
        onError: (error) => {
          console.error('Error al enviar el comentario:', error);
        }
      });
      setMessageInput(''); // Limpiar el input después de enviar el mensaje
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Evita que el formulario se envíe automáticamente
      sendMessage();
    }
  };

  const sendContextoMasivo = () => {
    
    if (postId) {
      setIsStreaming(true);
      const newMessage = {
        text: <><Puntitos /></> ,
        sender: 'user'
      };
      setCurrentChat([...currentChat, newMessage]);

      client.mutate({
        mutation: SUBMIT_CONTEXTOMASIVO_MUTATION,
        variables: { postId: postId},
        onError: (error) => {
          console.error('Error al enviar el comentario:', error);
        }
      });
      //setMessageInput(''); // Limpiar el input después de enviar el mensaje
      
    }

  };

  if (error) return <p>Error :(</p>;

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar); // Cambia el estado de visibilidad del sidebar
  };


  const handleDelete = () => {
    setAutoScrollEnabled(false);
    ///document.body.style.overflow = 'hidden';
  };

  return (
    <div className="chat-container">
      {/* Mensajes chat */}
      {loading ? (
        <div className="spinner-container">
          <div className="spinner">
            <img src='/images/logoNuevo.png' alt="loading" />
          </div>
        </div>
      ) : (
        isAuthenticated && (
          !currentChat || currentChat.length === 0 ? (
            <div className="spinner-container">
              <div className="contexto-container" >
                <h2>{titulo}</h2>
                <p>Por favor, presiona 'Testear modelo', para hacer preguntas y comprobar que el modelo ha sido entrenado de acuerdo a sus expectativas...</p>
                <button>
                  <a className='button-link' href="#" onClick={() => sendContextoMasivo()}>Testear modelo</a>
                </button>
              </div>
            </div>
          ) : (
            <div className="main2">
              <div className="messages" ref={bodymessagesEndRef}>
                {currentChat.map((message, index) => (
                  <div key={message.id} className={`message ${message.sender}`} ref={isRefActive ? messagesEndRef : null}>
                    {message.sender === 'bot' ? (
                      <>
                        <StreamingText text={message.text} setIsStreaming={setIsStreaming} isStreaming={isStreaming} />
                      </>
                    ) : (
                      <>
                        {currentChat.length > 1 && <img src={user.picture} alt={user.name} className='chat-img-user' />}
                        <span>{message.text}</span>
                        <span>
                          {post.id === postId && currentChat.length > 1 && 
                            <DeleteButton postId={post.id} index={index} commentId={message.commentId} username={post.username} refetch={refetch} setIsRefActive={setIsRefActive} onDelete={handleDelete} />}
                        </span>
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )
        )
      )}

      {/* BARRA PARTE SUPERIOR */}
      <div className="floating-menu">
        <img src='/images/LOGO-conductualGPT-01.svg' className="floating-menu-logo-2" />
        <div className="sidebar-buttons">
          {!isAuthenticated && (
            <button onClick={() => loginWithRedirect()}>
              <FaSignInAlt />
              <span>Ingresar</span>
            </button>
          )}
        </div>
        {isAuthenticated && (
          <div className="profile-container">
            <img src={user.picture} alt={user.name} />
            <div id="dropdown" className="dropdown">
              <button onClick={handleDropdownToggle}>
                <FaCaretDown />
              </button>
              {showDropdown && (
                <div className="dropdown-menu">
                 {user.sub === useradmin && (
                   <button>
                     <a className='button-link' href="#" onClick={() => navigate(`/trained`)}>Entrenar</a>
                   </button>
                  )}
                  <button>
                    <a className='button-link' href="#" onClick={() => setSuscripcionesOpen(true, setShowSidebar(false))}>Suscripcion</a>
                  </button>
                  <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                    <span>Salir</span>
                    <FaSignOutAlt />
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {/* Sidebar */}
      {isAuthenticated && (
        <div className={`sidebar ${showSidebar ? 'open' : 'close'}`}>
          <div className="sidebar-toggle" onClick={toggleSidebar}>
            {showSidebar ? <AbrirSide /> : <CerrarSide />}
          </div>
          <div className="sidebar-content">
            <div className="button-container">
              <button onClick={handleNewChat} disabled={loading}>
                <Nuevo size={10} />
                <p>Entrenar</p>
              </button>
            </div>
            <p>Historial</p>
            <FilteredPosts refetch={refetch} user={user.sub}/>
          </div>
        </div>
      )}

      {/* Input chat */}
      <div className="main">
        {isAuthenticated && (
          <div className="input-container">
            <input
              type="text"
              placeholder="Hazme una pregunta en relacion con la nueva información enseñada..."
              value={messageInput}
              onChange={(e) => setMessageInput(e.target.value)}
              onKeyDown={handleKeyDown}
              disabled={isStreaming} // Desactivar el input si isStreaming es true
            />
            <button onClick={sendMessage}><FaArrowRight size={15} /></button>
          </div>
        )}
        <div className="disclaimer-base-container">
          <p>Conductual-GPT ™ 2024 | by Nerualdinamica LLC</p>
        </div>
      </div>

      {/* Scrolling Button */}
      {isAuthenticated && (
        <>
          {bodymessagesEndRef.current && !isScrolledToBottom && (
            <div className="scroll-to-bottom" onClick={manualScrollToBottom}>
              <FaArrowDown />
            </div>
          )}
        </>
      )}
      <div className="disclaimer-base-container-terminos">
        <a className='button-link' href="#" onClick={() => setIsModalOpen(true)}>Terminos de uso.</a>
        <Terminos isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        <Suscripciones isOpen={isSuscripcionesOpen} onClose={() => setSuscripcionesOpen(false)} />
      </div>
    </div>
  );
};

export default Chat;



