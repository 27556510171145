import React, { useState } from 'react';
import './Cuarteto.css';

const Cuarteto = ({ words }) => {
  const initialState = {
    'Competitivo(a)': 'more',
    'Sociable': 'less'
  };

  const [selected, setSelected] = useState(initialState);

  const handleSelect = (word, type) => {
    setSelected((prevSelected) => ({
      ...prevSelected,
      [word]: type
    }));
  };

  return (
    <div className="cuarteto-group">
      
      {words.map((word, index) => (
        <div key={index} className="cuarteto-item">
          <div className="question-text">{word}</div>
          <div className="cuarteto-inputs">
            <div className="input-group">
              <label>
                <input
                  type="radio"
                  name={`more-${word}`}
                  checked={selected[word] === 'more'}
                  onChange={() => handleSelect(word, 'more')}
                  disabled={selected[word] !== 'more' && word !== 'Competitivo(a)'}
                />
              </label>
              <span>MÁS</span>
            </div>
            <div className="input-group">
              <label>
                <input
                  type="radio"
                  name={`less-${word}`}
                  checked={selected[word] === 'less'}
                  onChange={() => handleSelect(word, 'less')}
                  disabled={selected[word] !== 'less' && word !== 'Sociable'}
                />
              </label>
              <span>MENOS</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Cuarteto;




