import React from 'react';

const DescripcionConductualGPT = () => {
    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6', maxWidth: '800px', margin: '0 auto', textAlign: 'justify' }}>
            <h2 style={{ textAlign: 'center' }}>Descubre Conductual-GPT: Tu Asistente Personal para el Bienestar y el Crecimiento Personal</h2>
            <li>¡Bienvenido a Conductual-GPT, tu compañero de confianza en el camino hacia un bienestar integral y un crecimiento personal significativo! En un mundo lleno de desafíos y estrés, tener un aliado que te brinde información y consejos prácticos puede marcar la diferencia. Aquí es donde entra en juego Conductual-GPT.</li>

            <h3>¿Qué es Conductual-GPT?</h3>
            <li>Conductual-GPT es un avanzado sistema de chat impulsado por inteligencia artificial, diseñado para ayudarte a manejar el estrés, mejorar tu bienestar conductual y desarrollar hábitos positivos. Imagina tener un amigo comprensivo y siempre disponible, listo para escucharte y ofrecerte orientación basada en técnicas de lenguaje natural orientado a las conductas.</li>

            <h3>¿Cómo puede ayudarte Conductual-GPT?</h3>
            <ul>
                <li><strong>Apoyo Conductual 24/7:</strong> No importa la hora ni el lugar, Conductual-GPT está siempre disponible para escucharte y ofrecerte orientación e informasción básica conductual. Ya sea que estés pasando por un momento difícil o simplemente necesites alguien con quien reflexionar sobre ciertas conductas, estamos aquí para ti.</li>
                <li><strong>Gestión del Estrés y la Ansiedad:</strong> A través de información de técnicas efectivas de manejo del estrés y la ansiedad, Conductual-GPT te ofrece estrategias prácticas para enfrentar tus preocupaciones diarias y recuperar tu tranquilidad intelectual.</li>
                <li><strong>Desarrollo de Hábitos Saludables:</strong> ¿Quieres adoptar nuevos hábitos positivos pero no sabes por dónde empezar? Conductual-GPT te guía paso a paso, motivándote a establecer y mantener hábitos saludables que mejoren tu calidad de vida.</li>
                <li><strong>Mejora del Bienestar General:</strong> Desde consejos sobre sueño y ejercicio hasta técnicas de mindfulness y relajación, Conductual-GPT te proporciona un enfoque holístico para mejorar tu bienestar físico y conductual.</li>
                <li><strong>Orientación Personalizada:</strong> Cada persona es única, y así es nuestra orientación. Conductual-GPT adapta sus recomendaciones y consejos a tus necesidades específicas, asegurando que recibas la información más relevante y efectivo para ti.</li>
            </ul>

            <h3>Importante: Conductual-GPT no reemplaza a profesionales de la salud mental</h3>
            <li>Es fundamental recordar que Conductual-GPT no pretende ni puede reemplazar ni igualar bajo ninguna circunstancia el asesoramiento, diagnóstico o tratamiento proporcionado por psicólogos, psiquiatras u otros profesionales de la salud mental. Nuestro asistente está aquí para brindarte información muy básica sobre prácticas conductuales, ofreciéndote herramientas y estrategias para mejorar tu bienestar diario en condiciones normales. Para situaciones de emergencia, por favor, busque ayuda profesional inmediata.</li>

            <h3>Únete a Nuestra Comunidad de Bienestar</h3>
            <li>Miles de personas ya están transformando sus vidas con la ayuda de Conductual-GPT. No importa dónde te encuentres en tu viaje personal, nuestro asistente está aquí para apoyarte en cada paso del camino. ¡Empieza hoy y descubre el poder de tener un aliado dedicado a tu bienestar!... No olvides que es una simple guía informativa. Siempre recomendamos el apoyo de profesionales de la salud para situaciones que excedan el ámbito informativo.</li>

            <p style={{ textAlign: 'center' }}><strong>Con Conductual-GPT, no estás solo. Juntos, podemos lograr un futuro más brillante y saludable. ¡Vamos a empezar!</strong></p>
        </div>
    );
};

export default DescripcionConductualGPT;
