import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';

const dominio = process.env.REACT_APP_URI_P;

const httpLink = createHttpLink({
  uri:dominio
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
});

export default client;
