import React from 'react';

const TerminosDeUso = () => {
    return (
        <div style={{ fontSize: '16px', padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6', maxWidth: '800px', margin: '0 auto', textAlign: 'justify' }}>
            <h2 style={{ textAlign: 'center' }}>Términos de Uso del Sistema de Chat Asistente Conductual</h2>
            
            <li><strong>1. Aceptación de los Términos:</strong></li>
            <li>Al utilizar este sistema de chat asistente conductual, usted acepta cumplir y estar sujeto a estos términos de uso. Si no está de acuerdo con estos términos, no utilice el sistema.</li>

            <li><strong>2. Descripción del Servicio:</strong></li>
            <li>El sistema de chat asistente conductual proporciona información y apoyo sobre temas de comportamiento y bienestar general en condiciones normales. No reemplaza ni pretende igualar bajo ninguna circunstancia el asesoramiento profesional de un psicólogo, psiquiatra u otro profesional de la salud mental.</li>

            <li><strong>3. Uso Aceptable:</strong></li>
            <li>El usuario se compromete a utilizar el sistema de manera responsable y respetuosa. No se permite el uso del sistema para actividades ilegales, ofensivas, difamatorias, ni para acosar a otros usuarios.</li>

            <li><strong>4. Privacidad y Confidencialidad:</strong></li>
            <li>Se hará todo lo posible para proteger la privacidad y la confidencialidad de la información proporcionada por los usuarios. Sin embargo, no se garantiza la seguridad absoluta de la información transmitida a través del chat.</li>

            <li><strong>5. Limitación de Responsabilidad:</strong></li>
            <li>El sistema de chat asistente conductual se proporciona "tal cual" y "según disponibilidad". No garantizamos que el servicio sea ininterrumpido o libre de errores. No somos responsables de ningún daño directo, indirecto, incidental, especial o consecuente que resulte del uso o la imposibilidad de usar el servicio. Por favor revise las respuestas con profesionales por que al ser generadas por un sistema de IA, no aseguramos la veracidad de las mismas.</li>

            <li><strong>6. Modificaciones de los Términos:</strong></li>
            <li>Nos reservamos el derecho de modificar estos términos de uso en cualquier momento. Las modificaciones entrarán en vigor inmediatamente después de su publicación en esta página. Su uso continuado del sistema constituye su aceptación de los términos modificados.</li>

            <li><strong>7. Contacto:</strong></li>
            <li>Si tiene preguntas o inquietudes sobre estos términos de uso, por favor contáctenos a través de info@conductualgpt.com.</li>

            <hr />

            <li><strong>Nota:</strong></li>
            <li>Este sistema de chat asistente conductual está diseñado para proporcionar información y apoyo general en condiciones normales. Para situaciones de emergencia, por favor, busque ayuda profesional inmediata.</li>

            <hr />

            <li>Estos términos de uso fueron actualizados por última vez el el 21-02-2024.</li>

            <li>Por favor, asegúrese de leer y comprender estos términos antes de utilizar el sistema. Su uso constituye la aceptación de estos términos.</li>
        </div>
    );
};

export default TerminosDeUso;

