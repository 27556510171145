import { useEffect } from 'react';

const useClickOutside = (showDropdown, setShowDropdown) => {
  const handleClickOutside = (event) => {
    const dropdown = document.getElementById("dropdown");

    if (dropdown && !dropdown.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => handleClickOutside(event);

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showDropdown, setShowDropdown]);
};

export default useClickOutside;