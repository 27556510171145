import React, { useEffect, useRef, useState } from 'react';
import './estilos/Landing.css';
import Modal2 from './modal2';
import Modal3 from './modal3';
import Modal4 from './modal4';




  const Componente1 = () => {
    const videoRef = useRef(null);
    const videoElement = videoRef.current;
  useEffect(() => {
    
    if (videoRef.current) {
      const playPromise = videoRef.current.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            console.log("Reproducción automática exitosa");
          })
          .catch(error => {
            console.log("Reproducción automática fallida", error);
            
          });
      }
    }
  }, []);
    return (
      <div className="componente1">
           <video ref={videoRef} width="320" height="240" controls loop autoPlay muted playsInline>
           <source src="images/conductual-gpt.mp4" type="video/mp4" />
      </video>
      </div>
    );
  };

 

const Componente2 = () => {
    const [isModalOpen2, setIsModalOpen2] = useState(false );
    return <div className="componente2">
      <a className='button-link' href="#" onClick={() => setIsModalOpen2(true)}>Como me puede ayudar Conductual-GPT?</a>
      <Modal2 isOpen={isModalOpen2} onClose={() => setIsModalOpen2(false)} />
    </div>;
};
const Componente3 = () => {
    const [isModalOpen3, setIsModalOpen3] = useState(false );
    return <div className="componente3">
      <a className='button-link' href="#" onClick={() => setIsModalOpen3(true)}>Como funciona?</a>
      <Modal3 isOpen={isModalOpen3} onClose={() => setIsModalOpen3(false)} />
    </div>;
  };
  
  const Componente4 = () => {
    const [isModalOpen4, setIsModalOpen4] = useState(false );
    return <div className="componente4">
      <a className='button-link' href="#" onClick={() => setIsModalOpen4(true)}>Cuanto cuesta la suscripción?</a>
      <Modal4 isOpen={isModalOpen4} onClose={() => setIsModalOpen4(false)} />
    </div>;
  };


const Landing = () => {
  return (
    <>
    <div className="grid-container">
      <div className="grid-item">
        <Componente1 />
      </div>
    
      <div className="grid-item">
        <Componente2 />
        <Componente3 />
        <Componente4 />
      </div>
    </div>
    </>
    
  );
};



export default Landing;