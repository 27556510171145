import React, { useEffect, useRef } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import Cuarteto from './encuesta/ejemplo/Cuarteto';
import './Modal.css';

const words = ['Competitivo(a)', 'Sociable', 'Generoso(a)', 'Precavido(a)'];
const Modal = ({ isOpen, onClose }) => {
    const handleClose = () => {
      onClose();
    };
  
    if (!isOpen) {
      return null;
    }

  return (
    <>
      
        <div className="modal-wrapper-terminos">
          <div className="modal-content-terminos"><button className="close-button-terminos" onClick={handleClose}>
                <AiOutlineClose />
              </button>
            <div className="modal-header-terminos">
            Como usar la encuesta
            </div>
            <div className="modal-body-terminos">
            <p>
              Para cada conjunto de cuatro palabras (cuarteto), elija la palabra que más lo identifique en su comportamiento habitual y asígnela con un signo "+" (más). A continuación, elija la palabra que menos describa cómo se comporta cuando está en el estado identificado por la primera palabra y asígnela con un signo "-" (menos). Continúe con este proceso para todos los conjuntos de palabras.
            </p>
            <p><strong>Ejemplo:</strong></p>
          
            <p><em>Ejemplo:</em> Si "Competitivo(a)" lo describe mejor, asigne un "MAS" a "Competitivo(a)".</p>
            <p>A continuación, elija la palabra que menos lo describa cuando está en el estado identificado por la primera palabra.</p>
            <p><em>Ejemplo:</em> Si cuando está en modo "Competitivo(a)", es menos "Sociable", asigne un "MENOS" a "Sociable".</p>
            <p><strong>Cuarteto completo con signos:</strong></p>
            <div className="cuarteto">
              <Cuarteto words={words} />
            </div>
            </div>
          </div>
        </div>
      
    </>
  );
};

export default Modal;