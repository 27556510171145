import React, { useEffect, useState } from 'react';
import './SpinnerChat.css';

const Spinner = () => {
  const [dots, setDots] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => (prev < 3 ? prev + 1 : 0));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="spinnerpuntitos">
       <span className="dot" style={{ opacity: dots > 0 ? 1 : 0 }}> .</span>
      <span className="dot" style={{ opacity: dots > 0 ? 1 : 0 }}>.</span>
      <span className="dot" style={{ opacity: dots > 1 ? 1 : 0 }}>.</span>
      <span className="dot" style={{ opacity: dots > 2 ? 1 : 0 }}>.</span>
      
    </div>
  );
};

export default Spinner;
