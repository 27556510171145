import * as React from "react";

function IconPencilSquare(props) {
  return (
    <svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" version="1.1" viewBox="0 0 24 24">
  <defs>
    <style>
    {`
      .cls-1 {
        stroke-width: 0px;
      }

      .cls-1, .cls-2, .cls-3 {
        fill: none;
      }

      .cls-2 {
        stroke-width: 2px;
      }

      .cls-2, .cls-3 {
        stroke: #000;
        stroke-linecap: round;
        stroke-linejoin: round;
      }

      .cls-3 {
        stroke-width: 3px;
      }
      `}
    </style>
  </defs>
  <path className="cls-1" d="M0,0h24v24H0V0Z"/>
  <path className="cls-3" d="M13.2,21.5c-1,.2-2,.3-3.1.2-1.2-.1-2.4-.5-3.5-1.1l-3.5,2,.5-4.6c-1.5-2-2.3-4.5-2-7.2C2.2,5.2,7,1.2,12.3,1.8s8.9,5.2,8.6,10.6v.4"/>
  <path className="cls-2" d="M10.5,10.1v3.3s8.4,8.7,8.4,8.7c.9.9,2.3.9,3.2,0s.9-2.4,0-3.3l-8.4-8.7h-3.2"/>
</svg>
  );
}

export default IconPencilSquare;