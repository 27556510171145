import React from 'react';
import './App.css';
import Home from './componentes/landing-chat-gpt';
import Trained from './componentes/chat-gpt-tained';
import NewChat from './componentes/chat-gpt';
import SingleGpt from './componentes/single-chat-gpt';
import SingleTrained from './componentes/single-chat-trained';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

function App() {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return  <div className="spinner-container">
              <div className="spinner">
                <img src='/images/logoFinalDark.png' alt="loading" />
              </div>
            </div>;
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          {!isAuthenticated && (
            <Route path="/" element={<Home />} />
          )}
          {isAuthenticated && (
            <>
              <Route path="/new/chat" element={<NewChat />} />
              <Route path="/chat/:postId" element={<SingleGpt />} />
              <Route path="/singletrained/:postId" element={<SingleTrained />} />
              <Route path="/trained" element={<Trained />} />
              <Route path="/" element={<Navigate to="/new/chat" />} />
            </>
          )}
          <Route path="*" element={<Navigate to={isAuthenticated ? "/new/chat" : "/"} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
