import React from 'react';

function ArrowBarLeftIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      
    >
      <path d="M4 12l10 0" />
      <path d="M4 12l4 4" />
      <path d="M4 12l4 -4" />
      <path d="M20 4l0 16" />
    </svg>
  );
}

export default ArrowBarLeftIcon;
