import React from 'react';

const InstructivoChatAsistente = () => {
    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6', maxWidth: '800px', margin: '0 auto', textAlign: 'justify' }}>
            <h2 style={{ textAlign: 'center' }}>Cómo Funciona un Chat Asistente Conductual Asistido con Inteligencia Artificial Similar a Chat-GPT</h2>

            <h3>Introducción</h3>
            <li>Bienvenido al chat asistente conductual asistido con inteligencia artificial. Este sistema está diseñado para proporcionarte apoyo intelectual, orientación y estrategias prácticas para mejorar tu bienestar y calidad de vida conductual. A continuación, te explicamos cómo funciona y cómo puedes aprovechar al máximo sus capacidades.</li>

            <h3>Paso 1: Ingreso al Sistema</h3>
            <ol>
                <li><strong>Acceso:</strong> Ingresa al sistema a través de la plataforma proporcionada (por medio de un sitio web y proximamente por medio de una app movil).</li>
                <li><strong>Registro (si es necesario):</strong> Si es tu primera vez usando el sistema, es necesario crear una cuenta proporcionando información básica como tu nombre, fecha de nacimiento, correo electrónico y una contraseña segura.</li>
            </ol>

            <h3>Paso 2: Iniciar una Conversación</h3>
            <ol>
                <li><strong>Inicio del Chat:</strong> Una vez que hayas ingresado, encontrarás una interfaz de chat amigable y sencilla. Debrás completar un breve test (DiSC) muy simple.</li>
                <li><strong>Saludo Inicial:</strong> El asistente se presentará y te dará la bienvenida. Puedes iniciar la conversación escribiendo sobre tus sentimientos, comportamientos, preocupaciones o cualquier tema en el que necesites aprender de tí.</li>
            </ol>

            <h3>Paso 3: Interactuar con el Asistente</h3>
            <ol>
                <li><strong>Diálogo Abierto:</strong> Escribe tus preguntas o expresa tus emociones en el cuadro de chat. El asistente está diseñado para entender y responder en lenguaje natural. Si no sabes que preguntar, PREGUNTA ESO! (QUE PUEDO PREGUNTAR?)</li>
                <li><strong>Respuestas Personalizadas:</strong> El asistente utiliza inteligencia artificial para proporcionar respuestas empáticas y útiles basadas en el test DiSC conductual (Preguntale al chat que es disc!). Cada respuesta está adaptada a tus necesidades y contextos específicos. El Test es el punto de partida, la IA irá aprendiendo específicamente sobre ti a medida que vas dialogando.</li>
            </ol>

            <h3>Paso 4: Recibir Orientación y Consejos</h3>
            <ol>
                <li><strong>Estrategias de Manejo del Estrés:</strong> El asistente puede ofrecerte técnicas y ejercicios para manejar el estrés y la ansiedad, como la respiración profunda, la meditación o el establecimiento de rutinas saludables.</li>
                <li><strong>Desarrollo de Hábitos Positivos:</strong> Recibirás sugerencias y motivación para adoptar hábitos saludables, como el ejercicio regular, una alimentación balanceada y prácticas de sueño adecuadas.</li>
                <li><strong>Apoyo Emocional:</strong> El asistente está disponible para escucharte y proporcionarte informacion y un espacio de diálogo informativo, ayudándote a sentirte informado en momentos difíciles.</li>
            </ol>

            <h3>Paso 5: Información y Recursos Adicionales</h3>
            <ol>
                <li><strong>Artículos y Videos:</strong> El asistente puede recomendarte artículos, videos y otros recursos educativos que pueden ayudarte a entender e informarte mejor sobre emociones y comportamientos en general.</li>
                <li><strong>Recordatorios y Seguimiento:</strong> Puedes establecer recordatorios para practicar las técnicas sugeridas y recibir seguimiento regular para monitorear tu progreso.</li>
            </ol>

            <h3>Paso 6: Configuración y Personalización</h3>
            <ol>
                <li><strong>Preferencias:</strong> Ajusta las preferencias del chat según tus necesidades, como el tono de las respuestas, la frecuencia de los recordatorios y las áreas en las que necesitas más información. Esta adaptacion y personalizacion se hace sola a medida que interactuas con la IA.</li>
                <li><strong>Feedback: (PROXIMAMENTE)</strong> Proporcionaremos retroalimentación sobre tus experiencias con el asistente para mejorar continuamente la calidad del servicio.</li>
            </ol>

            <h3>Importante: Conductual-GPT no reemplaza a profesionales de la salud mental</h3>
            <li>Es fundamental recordar que Conductual-GPT no pretende ni puede reemplazar e igualar el asesoramiento, diagnóstico o tratamiento proporcionado por psicólogos, psiquiatras u otros profesionales de la salud mental. En caso de una crisis o necesidad de atención especializada, siempre busca ayuda profesional inmediata.</li>

            <h3>Conclusión</h3>
            <li>El chat asistente conductual asistido con inteligencia artificial está aquí para ayudarte a mejorar tu bienestar y calidad de vida a través de información y estrategias prácticas de conducta. Aprovecha todas las funcionalidades del sistema y recuerda que siempre estamos aquí para acompañarte en tu camino hacia un bienestar integral.</li>

            <p style={{ textAlign: 'center' }}><strong>¡Esperamos que esta descripción general te sea útil y te permita aprovechar al máximo las capacidades del asistente conductual!</strong></p>
        </div>
    );
};

export default InstructivoChatAsistente;
