import { useEffect } from 'react';

const useAutoScroll = (messagesEndRef, currentChat) => {
  const handleScrolling = () => {
    const scrollToBottom = (ref, delay) => {
      setTimeout(() => {
        if (ref && ref.current) {
          try {
            ref.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "end" });
          } catch (error) {
            console.error("Error scrolling into view:", error);
            setTimeout(() => {
              scrollToBottom(ref, 100);
            }, 100);
          }
        }
      }, delay);
    };

    const delays = [0, 200, 900, 1500, 2000];

    delays.forEach((delay) => scrollToBottom(messagesEndRef, delay));
  };

  useEffect(() => {
    handleScrolling();
  }, [currentChat]);
};

export default useAutoScroll;


