import React, { useState } from 'react';

const NuevoChatIcon = (index) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (

<>
    <span
    style={{ cursor: 'pointer', color: 'orange', padding: '15px 15px' }}
    onMouseEnter={() => setShowTooltip(index)}
    onMouseLeave={() => setShowTooltip(null)}
  >
   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
      <defs>
        <style>
          {`
            .cls-1 {
              stroke-width: 0px;
            }

            .cls-1, .cls-2 {
              fill: none;
            }

            .cls-2 {
              stroke: #000;
              stroke-linecap: round;
              stroke-linejoin: round;
              stroke-width: 2px;
            }
          `}
        </style>
      </defs>
      <path className="cls-1" d="M0,0h24v24H0V0Z"/>
      <path className="cls-2" d="M7,9.7c0-1.5,1.2-2.7,2.7-2.7h8.7c1.5,0,2.7,1.2,2.7,2.7v8.7c0,1.5-1.2,2.7-2.7,2.7h-8.7c-1.5,0-2.7-1.2-2.7-2.7v-8.7Z"/>
      <path className="cls-2" d="M4,16.7c-.6-.4-1-1-1-1.7V5c0-1.1.9-2,2-2h10c.8,0,1.2.4,1.5,1"/>
    </svg>


  </span>
  </>
   
  );
}

export default NuevoChatIcon;
