import { jsPDF } from 'jspdf';

const generatePdf = async (content) => {
  const doc = new jsPDF();
  const fontSize = 12;
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const margin = 30;
  const maxWidth = pageWidth - (0.1 * margin);
  const lineHeight = fontSize * 0.75;

  const contentLines = doc.splitTextToSize(content, maxWidth);

  const linesPerPage = Math.floor((pageHeight - 2 * margin) / lineHeight);
  let startY = margin;
  let currentPage = 1;

  const addPageNumber = (page) => {
      const pageNumber = `Página ${page}`;
      doc.setFontSize(10);
      doc.text(pageNumber, pageWidth / 2, pageHeight - margin, { align: 'center' });
  };

  contentLines.forEach((line, index) => {
      if (index > 0 && index % linesPerPage === 0) {
          addPageNumber(currentPage);
          doc.addPage();
          currentPage++;
          startY = margin;
      }

      const textX = margin;
      doc.setFontSize(fontSize);
      doc.text(line, textX, startY + (lineHeight * (index % linesPerPage)));
  });

  addPageNumber(currentPage);

  doc.save('Respuesta.pdf');
};

export { generatePdf };