// Importar React para poder usar JSX
import React, { useState, useEffect, useRef } from 'react';
import TextoOrganizado from '../render/TextoOrganizado';


const StreamingText = ({ text, setIsStreaming, isStreaming  }) => {
  const [displayText, setDisplayText] = useState('');
  const intervalRef = useRef(null);

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      if (index < 375 && isStreaming === true ) {
        
        setDisplayText(prevText => prevText + text.charAt(index));
      } else {
        setDisplayText(text);
        clearInterval(interval);
        setIsStreaming(false);
      }
      index++;
    }, 8);

    return () => clearInterval(interval);
  }, [text]);

  return (
    <>
      <span className="message"> <TextoOrganizado texto={displayText} /></span>
    </>
  );
};

export { StreamingText };