import { useState, useEffect } from 'react';

const useScrollToBottom = (bodymessagesEndRef) => {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  const checkIsScrolledToBottom = () => {
    if (bodymessagesEndRef.current) {
      return bodymessagesEndRef.current.scrollTop >= bodymessagesEndRef.current.scrollHeight - bodymessagesEndRef.current.clientHeight - 20;
    }
    return false;
  };

  useEffect(() => {
    setIsScrolledToBottom(checkIsScrolledToBottom());

    const handleScroll = () => {
      setIsScrolledToBottom(checkIsScrolledToBottom());
    };

    const current = bodymessagesEndRef.current;
    if (current) {
      current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (current) {
        current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [bodymessagesEndRef.current?.scrollHeight]);

  return isScrolledToBottom;
};

export default useScrollToBottom;